/* tslint:disable */
/* eslint-disable */
/**
 * OiOi content management API
 * OiOi content management API spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResourceLock
 */
export interface ResourceLock {
    /**
     * 
     * @type {Date}
     * @memberof ResourceLock
     */
    readonly expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ResourceLock
     */
    readonly userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceLock
     */
    readonly userDisplayName?: string;
}

export function ResourceLockFromJSON(json: any): ResourceLock {
    return ResourceLockFromJSONTyped(json, false);
}

export function ResourceLockFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceLock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expiresAt': !exists(json, 'expiresAt') ? undefined : (new Date(json['expiresAt'])),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userDisplayName': !exists(json, 'userDisplayName') ? undefined : json['userDisplayName'],
    };
}

export function ResourceLockToJSON(value?: ResourceLock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


