/* tslint:disable */
/* eslint-disable */
/**
 * OiOi content management API
 * OiOi content management API spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MediaType {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    PDF = 'PDF'
}

export function MediaTypeFromJSON(json: any): MediaType {
    return MediaTypeFromJSONTyped(json, false);
}

export function MediaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaType {
    return json as MediaType;
}

export function MediaTypeToJSON(value?: MediaType | null): any {
    return value as any;
}

