/* tslint:disable */
/* eslint-disable */
/**
 * OiOi content management API
 * OiOi content management API spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Resource,
    ResourceFromJSON,
    ResourceToJSON,
    ResourceLock,
    ResourceLockFromJSON,
    ResourceLockToJSON,
    ResourceType,
    ResourceTypeFromJSON,
    ResourceTypeToJSON,
    WallApplication,
    WallApplicationFromJSON,
    WallApplicationToJSON,
} from '../models';

export interface CreateResourceRequest {
    customerId: string;
    deviceId: string;
    applicationId: string;
    resource?: Resource;
    copyResourceId?: string;
    copyResourceParentId?: string;
}

export interface DeleteResourceRequest {
    customerId: string;
    deviceId: string;
    applicationId: string;
    resourceId: string;
}

export interface DeleteResourceLockRequest {
    customerId: string;
    deviceId: string;
    applicationId: string;
    resourceId: string;
}

export interface FindResourceRequest {
    customerId: string;
    deviceId: string;
    applicationId: string;
    resourceId: string;
}

export interface FindResourceLockRequest {
    customerId: string;
    deviceId: string;
    applicationId: string;
    resourceId: string;
}

export interface GetLockedResourceIdsRequest {
    applicationId: string;
    resourceId?: string;
}

export interface ImportWallApplicationRequest {
    wallApplication: WallApplication;
    customerId: string;
    deviceId: string;
    applicationId: string;
}

export interface ListResourcesRequest {
    customerId: string;
    deviceId: string;
    applicationId: string;
    parentId?: string;
    resourceType?: ResourceType;
}

export interface UpdateResourceRequest {
    resource: Resource;
    customerId: string;
    deviceId: string;
    applicationId: string;
    resourceId: string;
}

export interface UpdateResourceLockRequest {
    resourceLock: ResourceLock;
    customerId: string;
    deviceId: string;
    applicationId: string;
    resourceId: string;
}

/**
 * 
 */
export class ResourcesApi extends runtime.BaseAPI {

    /**
     * Create resource
     * Create a resource
     */
    async createResourceRaw(requestParameters: CreateResourceRequest): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createResource.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling createResource.');
        }

        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling createResource.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.copyResourceId !== undefined) {
            queryParameters['copyResourceId'] = requestParameters.copyResourceId;
        }

        if (requestParameters.copyResourceParentId !== undefined) {
            queryParameters['copyResourceParentId'] = requestParameters.copyResourceParentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}/devices/{deviceId}/applications/{applicationId}/resources`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceToJSON(requestParameters.resource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * Create resource
     * Create a resource
     */
    async createResource(requestParameters: CreateResourceRequest): Promise<Resource> {
        const response = await this.createResourceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an resource
     * Delete resource
     */
    async deleteResourceRaw(requestParameters: DeleteResourceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteResource.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deleteResource.');
        }

        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling deleteResource.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling deleteResource.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}/devices/{deviceId}/applications/{applicationId}/resources/{resourceId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an resource
     * Delete resource
     */
    async deleteResource(requestParameters: DeleteResourceRequest): Promise<void> {
        await this.deleteResourceRaw(requestParameters);
    }

    /**
     * Delete an resource lock
     * Delete resource lock
     */
    async deleteResourceLockRaw(requestParameters: DeleteResourceLockRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteResourceLock.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deleteResourceLock.');
        }

        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling deleteResourceLock.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling deleteResourceLock.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}/devices/{deviceId}/applications/{applicationId}/resources/{resourceId}/lock`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an resource lock
     * Delete resource lock
     */
    async deleteResourceLock(requestParameters: DeleteResourceLockRequest): Promise<void> {
        await this.deleteResourceLockRaw(requestParameters);
    }

    /**
     * Finds an resource
     * Finds a resource
     */
    async findResourceRaw(requestParameters: FindResourceRequest): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling findResource.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling findResource.');
        }

        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling findResource.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling findResource.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}/devices/{deviceId}/applications/{applicationId}/resources/{resourceId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * Finds an resource
     * Finds a resource
     */
    async findResource(requestParameters: FindResourceRequest): Promise<Resource> {
        const response = await this.findResourceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Finds an resource lock
     * Finds a resource lock
     */
    async findResourceLockRaw(requestParameters: FindResourceLockRequest): Promise<runtime.ApiResponse<ResourceLock>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling findResourceLock.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling findResourceLock.');
        }

        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling findResourceLock.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling findResourceLock.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}/devices/{deviceId}/applications/{applicationId}/resources/{resourceId}/lock`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceLockFromJSON(jsonValue));
    }

    /**
     * Finds an resource lock
     * Finds a resource lock
     */
    async findResourceLock(requestParameters: FindResourceLockRequest): Promise<ResourceLock> {
        const response = await this.findResourceLockRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns resource ids for locked resources
     * Returns resource ids for locked resources
     */
    async getLockedResourceIdsRaw(requestParameters: GetLockedResourceIdsRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling getLockedResourceIds.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.resourceId !== undefined) {
            queryParameters['resourceId'] = requestParameters.resourceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/application/{applicationId}/lockedResourceIds`.replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns resource ids for locked resources
     * Returns resource ids for locked resources
     */
    async getLockedResourceIds(requestParameters: GetLockedResourceIdsRequest): Promise<Array<string>> {
        const response = await this.getLockedResourceIdsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Endpoint for importing new content version from wall application JSON export file
     * Endpoint for importing new content version from wall application JSON export file
     */
    async importWallApplicationRaw(requestParameters: ImportWallApplicationRequest): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.wallApplication === null || requestParameters.wallApplication === undefined) {
            throw new runtime.RequiredError('wallApplication','Required parameter requestParameters.wallApplication was null or undefined when calling importWallApplication.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling importWallApplication.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling importWallApplication.');
        }

        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling importWallApplication.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}/devices/{deviceId}/applications/{applicationId}/importWallApplication`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WallApplicationToJSON(requestParameters.wallApplication),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * Endpoint for importing new content version from wall application JSON export file
     * Endpoint for importing new content version from wall application JSON export file
     */
    async importWallApplication(requestParameters: ImportWallApplicationRequest): Promise<Resource> {
        const response = await this.importWallApplicationRaw(requestParameters);
        return await response.value();
    }

    /**
     * List resources
     * List resources
     */
    async listResourcesRaw(requestParameters: ListResourcesRequest): Promise<runtime.ApiResponse<Array<Resource>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listResources.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling listResources.');
        }

        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling listResources.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.resourceType !== undefined) {
            queryParameters['resourceType'] = requestParameters.resourceType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}/devices/{deviceId}/applications/{applicationId}/resources`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceFromJSON));
    }

    /**
     * List resources
     * List resources
     */
    async listResources(requestParameters: ListResourcesRequest): Promise<Array<Resource>> {
        const response = await this.listResourcesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates resource
     * Updates resource
     */
    async updateResourceRaw(requestParameters: UpdateResourceRequest): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling updateResource.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateResource.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling updateResource.');
        }

        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling updateResource.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling updateResource.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}/devices/{deviceId}/applications/{applicationId}/resources/{resourceId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceToJSON(requestParameters.resource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * Updates resource
     * Updates resource
     */
    async updateResource(requestParameters: UpdateResourceRequest): Promise<Resource> {
        const response = await this.updateResourceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates resource lock
     * Updates resource lock
     */
    async updateResourceLockRaw(requestParameters: UpdateResourceLockRequest): Promise<runtime.ApiResponse<ResourceLock>> {
        if (requestParameters.resourceLock === null || requestParameters.resourceLock === undefined) {
            throw new runtime.RequiredError('resourceLock','Required parameter requestParameters.resourceLock was null or undefined when calling updateResourceLock.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateResourceLock.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling updateResourceLock.');
        }

        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling updateResourceLock.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling updateResourceLock.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}/devices/{deviceId}/applications/{applicationId}/resources/{resourceId}/lock`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceLockToJSON(requestParameters.resourceLock),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceLockFromJSON(jsonValue));
    }

    /**
     * Updates resource lock
     * Updates resource lock
     */
    async updateResourceLock(requestParameters: UpdateResourceLockRequest): Promise<ResourceLock> {
        const response = await this.updateResourceLockRaw(requestParameters);
        return await response.value();
    }

}
